import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const Sidebar = () => {
  let history = useHistory();
  const [menuState, setMenuState] = useState({});
  const location = useLocation();

  const toggleMenuState = (key) => {
    setMenuState(prevState => ({
      ...Object.keys(prevState).reduce((acc, stateKey) => {
        acc[stateKey] = false;
        return acc;
      }, {}),
      [key]: !prevState[key]
    }));
  };

  const isPathActive = (path) => location.pathname.startsWith(path);
  useEffect(() => {
    document.querySelector('#sidebar').classList.remove('active');
    setMenuState({});

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/maps', state: 'mapsMenuOpen' },
      { path: '/editors', state: 'editorsMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/general-pages', state: 'generalPagesMenuOpen' },
      { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },
      // ... other paths
    ];

    dropdownPaths.forEach(obj => {
      if (isPathActive(obj.path)) {
        setMenuState(prevState => ({ ...prevState, [obj.state]: true }));
      }
    });

    // Hover functionality
    const body = document.querySelector('body');
    const handleMouseOver = (el) => {
      if (body.classList.contains('sidebar-icon-only')) {
        el.classList.add('hover-open');
      }
    };
    const handleMouseOut = (el) => {
      if (body.classList.contains('sidebar-icon-only')) {
        el.classList.remove('hover-open');
      }
    };

    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', () => handleMouseOver(el));
      el.addEventListener('mouseout', () => handleMouseOut(el));
    });

    return () => {
      document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
        el.removeEventListener('mouseover', () => handleMouseOver(el));
        el.removeEventListener('mouseout', () => handleMouseOut(el));
      });
    };
  }, [location]);

  const handleLogout = (e) => {
    
    // history.push(`https://${process.env.REACT_APP_AWS_DOMAIN}/logout?client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&logout_uri=${encodeURIComponent('http://localhost:3000/logout')}`);
    history.push('/logout')

  }

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className={isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/dashboard">
            <i className="mdi mdi-monitor-dashboard menu-icon"></i>
            <span className="menu-title">Dashboard</span>
          </Link>
        </li>
        <li className={isPathActive('/ehs') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/ehs">
            <i className="mdi mdi-eye menu-icon"></i>
            <span className="menu-title">EHS Observation</span>
          </Link>
        </li>

        <li className={isPathActive('/eptw') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/eptw">
            <i className="mdi mdi-note-text-outline menu-icon"></i>
            <span className="menu-title">ePermit to Work</span>
          </Link>
        </li>

        <li className={isPathActive('/incident') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/incident">
            <i className="mdi mdi-alert-circle-outline menu-icon"></i>
            <span className="menu-title">Incident Reporting</span>
          </Link>
        </li>

        <li className={isPathActive('/inspection') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/inspection">
            <i className="mdi mdi-clipboard-text-search-outline menu-icon"></i>
            <span className="menu-title">Inspection</span>
          </Link>
        </li>

        <li className={isPathActive('/audit') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/audit">
            <i className="mdi mdi-clipboard-text-search menu-icon"></i>
            <span className="menu-title">Audit</span>
          </Link>
        </li>



        <li className={isPathActive('/plant') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/plant">
            <i className="mdi mdi-factory menu-icon"></i>
            <span className="menu-title">Plant and Equipment </span>
          </Link>
        </li>


        <li className={isPathActive('/report-data') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/report-data">
            <i className="mdi mdi-clipboard-check menu-icon"></i>
            <span className="menu-title">EHS Statistics </span>
          </Link>
        </li>

        <li className={isPathActive('/documents') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/documents">
            <i className="mdi mdi-file-document menu-icon"></i>
            <span className="menu-title">Documents Library </span>
          </Link>
        </li>

        <li className={isPathActive('/checklist') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/checklist">
            <i className="mdi mdi-bookmark-check menu-icon"></i>
            <span className="menu-title">Checklist Library </span>
          </Link>
        </li>

        <li className={isPathActive('/settings') ? 'nav-item active' : 'nav-item'}>
          <div className={menuState.appsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('appsMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-cog menu-icon"></i>
            <span className="menu-title">Settings</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={menuState.appsMenuOpen}>
            <ul className="nav flex-column sub-menu">

              <li className={isPathActive('/dropdown') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/dropdown">
                  <i className="mdi mdi-form-dropdown menu-icon"></i>
                  <span className="menu-title">Dropdown Config</span>
                </Link>
              </li>

              <li className={isPathActive('/user') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/user">
                  <i className="mdi mdi-badge-account menu-icon"></i>
                  <span className="menu-title">Internal Azure Users</span>
                </Link>
              </li>

              <li className={isPathActive('/app-user') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/app-user">
                  <i className="mdi mdi-home-account menu-icon"></i>
                  <span className="menu-title">External Users</span>
                </Link>
              </li>

              <li className={isPathActive('/master-user') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/master-user">
                  <i className="mdi mdi-account-group menu-icon"></i>
                  <span className="menu-title">Users Master Data</span>
                </Link>
              </li>

              <li className={isPathActive('/role-user') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/role-user">
                  <i className="mdi mdi-chart-bar menu-icon"></i>
                  <span className="menu-title">Assignment Report</span>
                </Link>
              </li>

              <li className={isPathActive('/project-user') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/project-user">
                  <i className="mdi mdi-selection-marker menu-icon"></i>
                  <span className="menu-title">Project Assignment</span>
                </Link>
              </li>

              <li className={isPathActive('/logout') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/logout">
                  <i className="mdi mdi-power menu-icon"></i>
                  <span className="menu-title">Logout</span>
                </Link>
              </li>


            </ul>
          </Collapse>
        </li>

      </ul>
    </nav>
  );
}




export default Sidebar;